import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <blockquote>
      <p parentName="blockquote">{`V1.1`}</p>
    </blockquote>
    <h2>{`Introduction`}</h2>
    <h3>{`Purpose`}</h3>
    <p>{`User access management is a set of measures and processes that aim to ensure that only authorized users have access to certain information or resources in a system or network. This management is essential to protect the security of information and ensure the confidentiality, integrity, and availability of data.`}</p>
    <h3>{`Accountabilities`}</h3>
    <p>{`It is the responsibility of the Information Systems Department to establish and follow a formal process for registering and deactivating user accounts. This includes granting and controlling access levels for each user, as well as conducting regular reviews to ensure that user access is appropriate and secure. These measures are critical for maintaining the confidentiality, integrity, and availability of the system's information and resources.`}</p>
    <h3>{`Extent`}</h3>
    <p>{`This document outlines the controls in place for managing user access.`}</p>
    <h2>{`User Registration`}</h2>
    <h3>{`Use of Unique User ID and Password`}</h3>
    <p>{`Each user must have a unique User ID and a secure password for accessing corporate information systems and networks. These should clearly indicate the user's name and must not be generic, job-related, project-specific, or anonymous.`}</p>
    <h3>{`Temporary User ID Expiration`}</h3>
    <p>{`User IDs assigned to non-internal organization collaborators, such as consultants, outsourcers, temporary workers, and others, should be created with an expiration date. If the expiration date is unknown, the default period of 30 days should be used.`}</p>
    <h3>{`Revocation of Access Privileges`}</h3>
    <p>{`All access to information systems should be revoked when it is no longer needed, including when employees leave the organization, service or outsourcing contracts end, or temporary work contracts expire. It is important to ensure timely deactivation of user accounts to maintain the security and integrity of the system.`}</p>
    <h3>{`Naming User IDs`}</h3>
    <p>{`User IDs should be created in accordance with the standards set by the Information Systems Department and should be consistent across all corporate information systems. This ensures consistent and secure access for users and helps to maintain the integrity of the system.`}</p>
    <h3>{`Access or Privileges Assignment Request`}</h3>
    <p>{`Requests for access or privileges to information systems should be made exclusively through the ticketing tool provided for this purpose by the Information Systems Department and authorized by the Human Resources Department. This ensures a standardized and secure process for granting access to the system.`}</p>
    <h3>{`Assigning User IDs`}</h3>
    <p>{`User IDs should only be granted after the user has signed a confidentiality agreement. This helps to ensure that the user understands and agrees to the terms and conditions of accessing the system and its information.`}</p>
    <h3>{`Communication of Human Resources Changes`}</h3>
    <p>{`The Human Resources Department is responsible for timely communication of any changes in the status, responsibilities, and duties of employees to the Information Systems Department. This ensures that the appropriate access and privileges are granted or revoked in a timely manner.`}</p>
    <h3>{`Information Transfer Responsibility`}</h3>
    <p>{`When an employee leaves a position, it is the responsibility of their manager to review all of their files with the goal of transferring ownership of any documents in their possession. This helps to ensure that documents are properly managed and safeguarded after the employee's departure.`}</p>
    <h3>{`Removing Employee Files After They Leave`}</h3>
    <p>{`Unless otherwise instructed, all files belonging to an employee should be removed four weeks after their departure from the company. This helps to ensure the security and integrity of the system and ensures that sensitive information is properly managed. It is important to establish clear guidelines for managing and disposing of documents after an employee leaves the organization.`}</p>
    <h2>{`Privilege Management`}</h2>
    <h3>{`Restricting Privileges - Need to Know`}</h3>
    <p>{`Access privileges to information systems granted to users, systems, and programs should be restricted based on the need to know. This helps to ensure that only those with a legitimate need for access are granted privileges, which helps to protect the security and integrity of the system. It is important to carefully consider and evaluate the level of access granted to each user, system, or program to ensure that access is appropriate and secure.`}</p>
    <h3>{`Special Privileges`}</h3>
    <p>{`Special system privileges, such as the ability to access files belonging to other users, should be restricted to users with responsibility for system and security management or systems. These privileges are powerful and should be granted with care, as they have the potential to affect the security and integrity of the system. It is important to carefully consider which users should have access to these privileges and to establish clear guidelines for their use.`}</p>
    <h3>{`Number of Privileged User IDs`}</h3>
    <p>{`The number of privileged User IDs should be limited to the minimum number necessary to meet business objectives. This helps to ensure that only those with a legitimate need for access to privileged information or resources are granted privileges, which helps to protect the security and integrity of the system. It is important to carefully consider and evaluate the level of access granted to each user to ensure that access is appropriate and secure.`}</p>
    <h3>{`Change of Productive Business Information`}</h3>
    <p>{`Access rights to production information systems should be configured to ensure that unauthorized personnel, including auditors, system administrators, programmers, and system operators, cannot alter business information. This helps to protect the integrity of the system and ensures that only authorized users can make changes to critical business information. It is important to carefully consider and evaluate the level of access granted to each user to ensure that access is appropriate and secure.`}</p>
    <h2>{`User Access Credential Management`}</h2>
    <h3>{`Initial Passwords`}</h3>
    <p>{`Initial passwords assigned by the security administrator should expire on the first login, requiring the user to choose a personal password. This helps to ensure that users have secure and unique passwords, which helps to protect the security and integrity of the system.`}</p>
    <h3>{`Initial Password Communication`}</h3>
    <p>{`The initial password should be communicated through a secure channel that includes internal mail and personal communication. This helps to ensure that the password remains confidential and is not compromised.`}</p>
    <h3>{`Password Reset Confirmation`}</h3>
    <p>{`Resetting a password should be accompanied by a confirmation email to the user, allowing them to detect and report illegitimate password resets. This helps to ensure that only authorized personnel can reset passwords and that users are notified of any changes to their access credentials.`}</p>
    <h3>{`Account Lockout for Access Attempts`}</h3>
    <p>{`All information systems should block users after three unsuccessful authentication attempts. Account unblocking should only be performed by the Information Systems Department after proper identification and authentication of the user. This helps to prevent unauthorized access and to ensure the security and integrity of the system.`}</p>
    <h3>{`Passwords Hardcoding in Applications`}</h3>
    <p>{`Passwords should never be included in the source code of internally programmed applications. This helps to protect sensitive information and ensure the security and integrity of the system.`}</p>
    <h3>{`Changing passwords after a System Intrusion`}</h3>
    <p>{`After the detection of an intrusion in a system, all programmed passwords in that system should be considered compromised and should be immediately changed. If the same passwords allow access to other systems, regardless of the existence of evidence of compromise, these systems should undergo a forensic analysis to identify potential intrusions and the passwords should be immediately changed as well.`}</p>
    <h3>{`Commitment of Management Accounts`}</h3>
    <p>{`In the event of evidence of compromise on an administrative account, it is necessary to promptly change the passwords of all users and conduct a forensic analysis of any affected systems. This will help to ensure the security and integrity of the affected systems and protect against further compromise.`}</p>
    <h2>{`Critical Analysis of User Access Privileges`}</h2>
    <h3>{`Reevaluation of Access Privileges`}</h3>
    <p>{`It is important to regularly review the access rights of each user to ensure that they have the minimum necessary level of access to perform their duties. This review should be conducted quarterly by the user's respective hierarchical superiors. `}</p>
    <h2>{`Attachments`}</h2>
    <p>{`This document does not contain any attachments. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      